@import '@/components/variables.scss';

.simple {

  display: inline-block;
  vertical-align: top;

  &-container {
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: var(--w_buttonBorderRadius);
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    
    @media screen and (max-width: $x-breakpoint-xs) {
      width: auto;
      align-items: flex-start;
      justify-content: flex-start;
      box-shadow: none;
      background-color: none;
    }
  }
  
  &-item {
    
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    padding-top: spacer(base);
    padding-bottom: spacer(base);
    position: relative;
    border: 3px solid white;
    border-radius: var(--w_buttonBorderRadius);
    padding-left: spacer(xl);
    padding-right: spacer(xl);
      
    @media screen and (max-width: $x-breakpoint-xs) {
      border: 0px;
      background-color: white;
    }

    label {
      display: block;
    }
    
    &-selected {
      background-color: var(--w_primaryColor);
      color: var(--w_primaryText);
    }
  }
  
  &-bar {
    
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 5px;
    background-color: var(--w_primaryColor);
    transition: all 0.5s ease;
    
  }
  
  &-selected {
    left: 0;
    width: 100%;
  }

}
