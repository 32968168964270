@import '@/components/variables.scss';

.popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
  z-index: -1;
  &-show {
    z-index: 300;
    opacity: 1;
  }
  @media screen and (max-width: $x-breakpoint-xs) {
    opacity: 1;
    align-items: flex-end;
    justify-content: flex-end;
    height: calc(100vh - env(--safe-area-inset-bottom));
  }
}

.popup {

  flex: 0 0 auto;
  display: block;
  background-color: white;
  border-radius: var(--w_tileBorderRadius);
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  z-index: -1;
  overflow: hidden;
  min-width: 420px;
  min-height: 100px;
  max-height: calc(100vh - 48px);
  max-width: calc(100vw - 48px);
  transition: all 0.5s ease;
  transform: scale(0);
  
  @media screen and (max-width: $x-breakpoint-xs) {
    transform: none;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: fixed;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 !important;
  }

  &-show {
    z-index: 300;
    transform: scale(1);
    @media screen and (max-width: $x-breakpoint-xs) {
      transform: none;
      top: 0;
    }
  }

  &-inner {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    form {
      flex: 1 1 auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: auto;
    }

  }
  
}

.backdrop {

  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.1);
  z-index: -1;
  opacity: 0;
  
  &-show {
    opacity: 1;
    z-index: 98;
  }

}

.close {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  color: #999999;
  cursor: pointer;
  margin-right: spacer(base);
  > span {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }
  svg {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
  }
}

.close-float {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  border-radius: 100%;
  margin-right: 0;
}

.header {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  height: 64px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--w_borderColor);
  h2 {
    flex: 1 1 auto;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
    padding-left: spacer(xl);
  }
}

.popup:has(.header) .close-float {
  display: none;
}

.body {
  flex: 1 1 auto;
  display: block;
  padding: spacer(xl);
  overflow-y: auto;
  position: relative;
}

.footer {
  flex: 0 0 auto;
  display: block;
  padding: spacer(base) spacer(xl);
  border-top: 1px solid var(--w_borderColor);
}


.toaster {
  display: block;
  position: fixed;
  bottom: 0;
  left: spacer(base);
  z-index: 1;
}
.toast {
  display: block;
  background-color: white;
  padding: spacer(xl);
  animation: toast 0.5s both;
  border-radius: var(--w_tileBorderRadius);
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

@keyframes toast {
  0% {
    margin-bottom: calc(-100% - 72px)
  }
  100% {
    margin-bottom: 72px;
  }
}